import { createNamespacedHelpers } from "vuex";
import { EventBus } from "@/event-bus";

const companyHelper = createNamespacedHelpers("company");
const reportsHelper = createNamespacedHelpers("reports");

export default {
  data() {
    return {
      editingMode: null,
      financialsVisualKey: this.generateUuid(),
    };
  },

  computed: {
    ...companyHelper.mapGetters({
      financialsInitialized: "hasInitializedFinancials",
      initializingFinancials: "initializingFinancials",
      getFinancials: "getFinancials",
    }),

    ...reportsHelper.mapGetters(["getElements", "isCorporateGroupReport"]),

    reportTypeOptions() {
      const translations =
        this.$i18n.messages[this.$i18n.locale].models.report.types;
      const EXCLUDED_TYPES = [
        "economy_dashboard",
        "economy_corporate_group_dashboard",
        "general",
      ];
      if (!this.hasCorporateGroup) {
        EXCLUDED_TYPES.push("corporate_group");
      }

      const types = Object.keys(translations)
        .filter((type) => !EXCLUDED_TYPES.includes(type))
        .map((key) => {
          return {
            value: key,
            text: translations[key],
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));

      // put general/no_type first in the list
      const no_type = { value: "general", text: translations["general"] };
      return [no_type, ...types];
    },
  },

  mounted() {
    EventBus.on("RELOAD_FINANCIAL_ELEMENTS", this.newFinancialsVisualKey);
    EventBus.on("LOADED_CONFIGURATIONS", this.newFinancialsVisualKey);
  },

  methods: {
    ...companyHelper.mapMutations(["setFinancials"]),
    ...reportsHelper.mapActions(["addComponentToLibrary"]),

    reportElementComponent(elementType) {
      switch (elementType) {
        case "decisions":
          return "DecisionsElement";
        case "image":
          return "ImageElement";
        case "page_break":
          return "PageBreakElement";
        case "report_columns":
          return "ReportColumnsElement";
        case "space":
          return "SpaceElement";
        case "tasks":
          return "TasksElement";
        case "text":
          return "TextElement";
        case "tiptap_text":
          return "TiptapTextElement";
        case "tiptap_title":
          return "TiptapTitleElement";
        case "title":
          return "TitleElement";
        case "group_tree":
        case "report_table":
        case "graph":
        case "widget":
          return "FinancialElement";
        default:
          return "";
      }
    },

    newFinancialsVisualKey() {
      // Re-render the financials visual elements using this key when the configurations are loaded
      this.financialsVisualKey = this.generateUuid();
    },

    visualbyReportFinancialUuid(
      report_type = "general",
      corporate_group_data = false
    ) {
      if (
        corporate_group_data ||
        report_type == "corporate_group" ||
        report_type == "economy_corporate_group_dashboard"
      ) {
        this.setFinancials({
          displayCorporateGroup: true,
        });
      } else {
        this.setFinancials({
          displayCorporateGroup: false,
        });
      }
    },

    visualbyReportSetDateString(dateString) {
      if (
        window.wzGetController &&
        typeof window.wzGetController === "function"
      ) {
        // The end date is as a string until we send it to VisualBy.
        // We have to use `Date.parse` to get the date in the current timezone instead
        // of UTC.
        window.wzGetController()?.setEndDate(Date.parse(dateString));

        this.setFinancials({
          endDateString: dateString,
        });
      }
    },

    visualbyReportSetFeature(feature, enabled) {
      if (
        window.wzGetController &&
        typeof window.wzGetController === "function"
      ) {
        // Use setTimeout to avoid problems with Angular digest cycle
        setTimeout(() => {
          window.wzGetController()?.setFeature(feature, enabled);
        }, 0);
      }
    },

    reportEndDateString(report) {
      if (!report) {
        return null;
      }

      if (report.type !== "Report") {
        return null;
      }

      if (report.period_end instanceof Date) {
        return report.period_end.toISOString();
      }

      return report.period_end;
    },

    reportFinancialsUuid(report) {
      if (!report) {
        return null;
      }

      if (this.$currentAdminPanel?.financials_uuid) {
        return this.$currentAdminPanel.financials_uuid;
      } else if (report.use_corporate_group_data) {
        return this.$currentCompany.corporate_group_uuid;
      } else {
        return this.$currentCompany.financials_uuid;
      }
    },

    // Allows us to show image url even if it is not saved
    imageElementUrl(element) {
      let image = element.image;
      if (!image) {
        return null;
      }

      if (element.image_url) {
        return element.image_url;
      } else if (element.image.metadata.mime_type === "application/pdf") {
        // If it is a PDF and we have no image_url, the derivates are not ready yet.
        return null;
      } else if (element.image.url) {
        return element.image.url;
      } else if (element.image.dataURL) {
        return element.image.dataURL;
      }
      return null;
    },

    elementOrigin(element, report) {
      if (
        !this.hasActiveFeature("economy-business-unit") &&
        !this.isCorporateGroupReport
      ) {
        return null;
      }

      return element.origin || report.origin || null;
    },

    getConfiguration(configuration) {
      if (
        window.wzGetController &&
        typeof window.wzGetController === "function" &&
        window.wzGetController()
      ) {
        return window
          .wzGetController()
          .sessionService.repositories[
            this.$activeFinancialsUuid
          ].configurationTree.findConfigurations((config) => config.reportConfiguration.uuid == configuration, [])[0]
          ?.reportConfiguration;
      }
    },

    getConfigurationName(configuration) {
      const reportConfiguration = this.getConfiguration(configuration);
      if (reportConfiguration) {
        return window
          .wzGetController()
          .translate(reportConfiguration?.label || "");
      }
    },

    async addFinancialComponentToLibrary(component) {
      const modalId = this.generateUuid();
      const isConfirmed = await this.promptConfirm({
        title: this.$t(
          "models.report_component.confirms.financials_add_to_components.title"
        ),

        description: this.$t(
          "models.report_component.confirms.financials_add_to_components.description"
        ),

        id: modalId,
        stayOpenAfterConfirm: true,
      });

      if (isConfirmed) {
        await this.addComponentToLibrary(component);
        try {
          wzGetController()
            ?.getRepository(this.$activeFinancialsUuid)
            ?.refreshConfigurations();
        } catch (error) {
          console.error("Error refreshing configurations", error);
        }
        this.$beModal.hide(modalId);
      }
    },
  },
};
